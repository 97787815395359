import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './SearchAdvanced.scss';

const SearchAdvanced = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Container className="search-advanced">
                <Row className="justify-content-center">
                    <Col>
                        <InputGroup className="mb-3 search-advanced--input">
                            <Form.Control
                                placeholder="Elige tu destino"
                                aria-label="Elige tu destino"
                                aria-describedby="search-query"
                            />
                            <Form.Control
                                placeholder="Llegada -> Salida"
                                aria-label="Llegada -> Salida"
                                aria-describedby="search-query"
                            />
                            <Form.Control
                                placeholder="Huéspedes"
                                aria-label="Huéspedes"
                                aria-describedby="search-query"
                            />
                            <Button variant="secondary" className="rounded-pill">Buscar <span className="ico ico-search"></span></Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SearchAdvanced;