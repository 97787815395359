import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './SearchHero.scss';

const SearchHero = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Container>
                <Row>
                    <Col className='text-center py-5'>
                        <p className='subtitle'>PROPIEDADES EN VENTA</p>
                        <h1 className="text-uppercase font-styled-bold text-secondary size-title mb-4">TU HOGAR EN LA PLAYA</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg='4'>
                        <InputGroup className="mb-3 search-hero--input">
                            <Form.Control
                                placeholder="Elige tu destino"
                                aria-label="Elige tu destino"
                                aria-describedby="search-query"
                            />
                            <InputGroup.Text id="search-query"><span className="ico ico-search"></span> Search</InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SearchHero;