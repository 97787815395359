import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './Property.scss';
import Layout from "../../components/Layout/Layout";
import Map from "../../components/Map/Map";

import samplePropertyList from '../../data/propertyList';
import Slider from "../../components/Slider/Slider";


const Property = (props) => {
    const { slug } = useParams();
    const { t, i18n } = useTranslation();

    const [property, setProperty] = useState(null);

    useEffect(() => {
        let p = samplePropertyList.find(p => p.slug === slug);
        setProperty(p);
    },[])

    return (
        <>
            <Layout>
                <section className="bg-secondary-light pt-5">
                    <Container>
                        <Row className="align-items-end mb-4">
                            <Col lg='8'>
                                <p>
                                    <small>PROPIEDADES / DESTINOS / {property?.location}</small>
                                </p>
                                <h1>{property?.name} - {property?.account_name}</h1>
                            </Col>
                            <Col className="text-center">
                                <Button variant='primary'>CONTACTA A UN ASESOR</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='8' className="property--main-slider">
                                { property?.gallery &&
                                    <Slider slides={property?.gallery} />
                                }
                            </Col>
                            <Col>
                                <span className="property--second-image property--gallery-sub-image" style={{ backgroundImage: `url(${property?.image})` }}></span>
                                <span className="property--third-image  property--gallery-sub-image" style={{ backgroundImage: `url(${property?.image})` }}>
                                    <Button variant='info' className="btn-gallery bg-white" size="sm">Más fotos</Button>
                                </span>
                            </Col>
                        </Row>
                        <Row className="pt-4">
                            <Col lg='8'>
                                <Row>
                                    <Col>
                                        <Row className="mb-5">
                                            <span className="subtitle">ATRIBUTOS</span>
                                            <div>
                                                {property?.rooms} hab | {property?.bathrooms} baño | {i18n.language == "es" ? `${property?.meters}m2` : `${property?.squarefeets}sqft`}
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <span className="subtitle">UBICACIÓN</span>
                                                <div>
                                                    {property?.location}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mb-5">
                                            <span className="subtitle">ESTE HOGAR:</span>
                                            <div>
                                                {property?.description}
                                            </div>
                                        </Row>
                                        <Row className="mb-5">
                                            <span className="subtitle">AMENIDADES</span>
                                            <div>
                                                <ul>
                                                    <li>Item</li>
                                                    <li>Item</li>
                                                    <li>Item</li>
                                                    <li>Item</li>
                                                    <li>Item</li>
                                                </ul>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <span className="subtitle">CARACTERÍSTICAS</span>
                                        <ul>
                                            <li>Item 1</li>
                                            <li>Item 1</li>
                                            <li>Item 1</li>
                                            <li>Item 1</li>
                                            <li>Item 1</li>
                                        </ul>
                                        <Button variant='primary' className="my-4">CONTACTA A UN ASESOR</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-5">
                            <Col md='4'>
                                <span className="subtitle">UBICACIÓN</span>
                                <div>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Obcaecati aspernatur velit, recusandae minima tempora eos nisi reiciendis quia distinctio rem ullam voluptas totam voluptates eius alias saepe voluptate aut facere?
                                </div>
                            </Col>
                            <Col md='8' style={{ position: "relative", minHeight: "400px" }}>
                                <Map 
                                    center={{
                                        lat: 27.971278,
                                        lng: -111.012778
                                    }} />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default Property;