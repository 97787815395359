import { Button } from "react-bootstrap";
import PropertyCard from "../PropertyCard/PropertyCard";

const PropertyList = (props) => {
    const { properties, maxItems } = props;



    return (
        <>
            <div className="apartment-list apartment-list--grid">
                { properties.map((p,key) => 
                    <PropertyCard property={p} variant='grid' key={key} />
                )}
            </div>
            <div className="apartment-list--show-more text-center">
                <Button variant='primary' size="sm" className="rounded-pill py-2 mt-4 mb-2">Más lugares</Button>
            </div>
        </>
    )
}

export default PropertyList;