import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";

import SearchHero from "../../components/SearchHero/SearchHero";
import PropertyList from "../../components/PropertyList/PropertyList";
import samplePropertyList from './../../data/propertyList';
import ContactForm from "../../components/ContactForm/ContactForm";

const Buy = () => {
    const { t, i18n } = useTranslation();
    const [featuredProperties, setFeaturedProperties] = useState([]);

    useEffect(() => {
        setFeaturedProperties(samplePropertyList)
    },[])

    return (
        <Layout>
            <Container fluid className="bg-secondary-light p-5">
                <Row>
                    <Col>
                        <SearchHero />
                    </Col>
                </Row>
            </Container>
            <section className="bg-secondary-light p-5">
                <Container>
                    <Row>
                        <Col>
                            <div className="subtitle mb-4">ELIGE TU PROPIEDAD</div>
                            <PropertyList properties={featuredProperties} maxItems={6} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactForm />
        </Layout>
    )
}

export default Buy;