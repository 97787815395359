import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./i18n";
import './App.scss';
import { LangContext } from "./LangContext";
import { useTranslation } from "react-i18next";

import Home from './pages/Home/Home';
import Fraccional from "./pages/Fraccional/Fraccional";
import Amenities from "./pages/Amenities/Amenities";
import Apartments from "./pages/Apartments/Apartments";
import Community from "./pages/Community/Community";
import Contact from "./pages/Contact/Contact";
import Privacy from './pages/Privacy/Privacy';
import FAQs from './pages/FAQs/FAQs';

import Buy from "./pages/Buy/Buy";
import Rental from "./pages/Rental/Rental";
import Property from "./pages/Property/Property";
import PropertyRental from "./pages/PropertyRental/PropertyRental";


function App() {
  const { t, i18n } = useTranslation();

  return (
    <LangContext.Provider value={{
      changeLang: langCode => {
        localStorage.setItem("i18n",langCode);
        window.location.reload(true);
        i18n.changeLanguage(langCode);
      }
    }}>
    <Router>
      <Routes>
          <Route exact path="/renta/:slug" element={<PropertyRental />}/>
          <Route exact path="/compra/:slug" element={<Property />}/>
          <Route exact path="/compra" element={<Buy />}/>
          <Route exact path="/renta" element={<Rental />}/>

          <Route exact path="/faqs" element={<FAQs />}/>
          <Route exact path="/privacidad" element={<Privacy />}/>
          <Route exact path="/contacto" element={<Contact />}/>
          <Route exact path="/comunidad" element={<Community />}/>
          <Route exact path="/departamentos" element={<Apartments />}/>
          <Route exact path="/amenidades" element={<Amenities />}/>
          <Route exact path="/fraccional" element={<Fraccional />}/>
          <Route exact path="/" element={<Home />}/>
      </Routes>
    </Router>
    </LangContext.Provider>
  );

}

export default App;
