import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";

import Slider from "../../components/Slider/Slider";
import SearchAdvanced from "../../components/SearchAdvanced/SearchAdvanced";
import PropertyList from "../../components/PropertyList/PropertyList";
import samplePropertyList from './../../data/propertyList4rent';
import ContactForm from "../../components/ContactForm/ContactForm";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";

const Rental = () => {
    const { t, i18n } = useTranslation();
    const [featuredProperties, setFeaturedProperties] = useState([]);

    const mainSlider = [
        {
            id: 1,
            image: "./images/amenities/EVAMAR_SC_LOBBY.png",
            alt: 'First Slide',
            title: 'Disfruta de<br /> unas vacaciones<br /> inolvidables',
        },
    ];

    useEffect(() => {
        setFeaturedProperties(samplePropertyList)
    },[])

    return (
        <Layout>
            <Slider slides={mainSlider} controls={false} />
            <Container fluid style={{ position:'relative', zIndex: 1 }}>
                <Row className="mt-n4">
                    <Col>
                        <SearchAdvanced />
                    </Col>
                </Row>
            </Container>
            <section className="community-hero bg-secondary-light pt-5">
                <Container className='text-center text-md-start'>
                    <Row className='pt-4 justify-content-md-center'>
                        <Col className="text-center">
                            <h2 className="font-styled text-primary py-0 my-0">Encuentra el lugar perfecto</h2>
                            <p className="text-center mt-1 pt-1 pt-sm-0">
                                Para ti, para la familia o para compartir con amigos, tenemos un lugar a tu gusto.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-light p-5">
                <Container>
                    <Row>
                        <Col>
                            <PropertyList properties={featuredProperties} maxItems={6} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-conexion-light pt-2">
                <Container className='text-center text-md-start mt-5'>
                    <Row className='pt-5 text-center'>
                        <Col className="px-5 pt-3">
                            <img src="./images/img-community-app.jpg" alt="" />
                        </Col>
                        <Col lg='8' className="px-5 py-3">
                            <h2 className="text-center mb-3">{t("rental.app.title")}</h2>
                            <p className="text-center" dangerouslySetInnerHTML={{ __html: t("rental.app.intro") }}></p>
                            <Row className='app-features pt-3 pb-2 text-start'>
                                <Col className="px-1 py-3" xl='4'>
                                    <Row className='align-items-center text-center'>
                                        <Col xs='4' lg='12'>
                                            <img src="./images/ico-app-management.svg" alt="" />
                                        </Col>
                                        <Col xs='8' lg='12'>
                                            <p dangerouslySetInnerHTML={{ __html: t("rental.app.feature1.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="px-1 py-3" xl='4'>
                                    <Row className='align-items-center text-center'>
                                        <Col xs='4' lg='12'>
                                            <img src="./images/ico-app-booking.svg" alt="" />
                                        </Col>
                                        <Col xs='8' lg='12'>
                                            <p dangerouslySetInnerHTML={{ __html: t("rental.app.feature2.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="px-1 py-3" xl='4'>
                                    <Row className='align-items-center text-center'>
                                        <Col xs='4' lg='12'>
                                            <img src="./images/ico-app-experiences.svg" alt="" />
                                        </Col>
                                        <Col xs='8' lg='12'>
                                            <p dangerouslySetInnerHTML={{ __html: t("rental.app.feature3.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="app-links justify-content-center">
                                <Col lg='3'>
                                    <Button href="https://apps.apple.com/mx/app/evamar-habitantes/id1522629181" target="_blank" variant="text" className="display-inline"><img width="120px" src="images/btn-app-store.png" alt="" /></Button>
                                </Col>
                                <Col lg='3'>
                                    <Button href="https://play.google.com/store/apps/details?id=mx.grupoevamar.appevamar&hl=es&gl=MX" target="_blank" variant="text" className="display-inline"><img height="40px" src="images/btn-play-store.png" alt="" /></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-light py-4">
                <Container className='text-center text-md-start'>
                    <Row className='pt-5 text-center'>
                        <h3 className="">Nuestros huéspedes</h3>
                    </Row>
                </Container>
                <CustomerReviews />
            </section>
            <ContactForm />
        </Layout>
    )
}

export default Rental;