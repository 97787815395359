import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <>
            { !['faqs','compra','renta','marketplace'].includes(location.pathname.split('/')[1]) &&
                <section className="bg-secondary-med faqs-link">
                    <Container className='text-center'>
                        <Row className='py-5'>
                            <Col className="camp--bg">
                                <h2 className='font-styled-bold text-secondary'>{t("footer.faqs.title")}</h2>
                                <p>{t("footer.faqs.desc")}</p>
                                <Button href="/faqs" variant="secondary-high" className="text-white">{t("buttons.faqs")}</Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
            <footer className='bg-secondary-light'>
                <Container className='text-center text-md-start pt-5 py-2'>
                    <Row className='mt-3'>
                        <Col md='3' lg='4' xl='3' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4  text-secondary'>
                                SHOWROOM
                            </h6>
                            <p>
                                Luis Donaldo Colosio #887<br />
                                Plaza La Encantada, Local 47<br />
                                83224 Hermosillo, Son.
                            </p>
                            <p>
                                <a href={`https://api.whatsapp.com/send?phone=526624617993&text=${t("whatsapp.message")}`} target="_blank"><span className="icon icon--whatsapp"></span> (662) 461 7993</a>
                            </p>
                        </Col>

                        <Col md='3' lg='3' xl='3' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4  text-secondary'>SAN CARLOS</h6>
                            <p>
                                Blvd. Rafael Jesús Caballero<br />
                                Predio El Baviso, porción B.<br />
                                San Carlos Nvo. Guaymas, Son.
                            </p>
                        </Col>

                        <Col md='3' lg='2' xl='2' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4  text-secondary'>{t("footer.followus")}</h6>
                            <p>
                                <a href='https://www.facebook.com/Evamar-108575677652543/about/?ref=page_internal' target="_blank" className='text-reset'>
                                    <span className="icon icon--facebook"></span>
                                </a>
                                <a href='https://www.instagram.com/evamarmx/' target="_blank" className='text-reset'>
                                    <span className="icon icon--instagram"></span>
                                </a>
                                <a href='https://www.linkedin.com/company/evamar-comunidad-viva/' target="_blank" className='text-reset'>
                                    <span className="icon icon--linkedin"></span>
                                </a>
                                <a href='https://www.youtube.com/@evamar1346' target="_blank" className='text-reset'>
                                    <span className="icon icon--youtube"></span>
                                </a>
                            </p>
                        </Col>

                        <Col md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4  text-secondary'>{t("footer.about")}</h6>
                            <p>
                                <a href='/faqs' className='text-reset'>
                                {t("footer.about.faqs")}
                                </a>
                            </p>
                            <p>
                                <a href='/contacto' className='text-reset'>
                                {t("footer.about.contact")}
                                </a>
                            </p>
                        </Col>
                        <Col md='4' lg='3' xl='2' className='mx-auto mb-md-0 mb-4'>
                            <p>
                            <a href="https://re-ti.mx" target='_blank'><img className='author-logo' src="/images/lg-reti.png" /></a>
                                {t("footer.author")} <br />
                                <a href='/privacidad' className='text-reset'>{t("footer.privacy")}</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer;