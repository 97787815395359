
const propertyList = [
    {
        id: 1,
        slug: 'sample',
        name: 'A.101',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=1',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 2,
        slug: 'sample-2',
        name: 'A.702',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=2',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 3,
        slug: 'sample-3',
        name: 'A.203',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=3',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 4,
        slug: 'sample-4',
        name: 'A.702',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=4',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 5,
        slug: 'sample-5',
        name: 'A.203',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=5',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 6,
        slug: 'sample-6',
        name: 'A.203',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=6',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
];

export default propertyList;