import React, { useState } from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import mapStyle from "./MapStyle";


const MapContainer = (props) => {

    const { points, center } = props;
    const {
        showingInfoWindow,
        activeMarker,
        selectedPlace,
      } = useState(null);

    
    var bounds = new props.google.maps.LatLngBounds();
    if(points)
        for (var i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
        }

    const onMarkerClick = () => {}
    const onSpotClick = () => {

    }
    const onInfoWindowClose = () => {}
    
    const mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle
        })
        map.fitBounds(bounds);
    }

    return (
        <Map 
            google={props.google} 
            zoom={14} bounds={bounds}
            initialCenter={center}
            containerStyle={{ width: "calc(100% - var(--bs-gutter-x) * 1)" }}
            onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        >
   
            <Marker 
                onClick={onMarkerClick}
                name={'evamar'} 
                icon={{
                    url: "/images/ico-map-marker.png",
                    //anchor: new props.google.maps.Point(16,16),
                    size: new props.google.maps.Size(50,69),
                    scaledSize: new props.google.maps.Size(50,69)
                }}
            />
            { points && points.map((item,key) => {
                    return (
                        <Marker 
                            onClick={onSpotClick}
                            position={{lat: item.lat, lng: item.lng}}
                            icon={{
                                url: "/images/ico-map-spot.png",
                                //anchor: new props.google.maps.Point(16,16),
                                size: new props.google.maps.Size(20,20),
                                scaledSize: new props.google.maps.Size(20,20)
                            }}
                            label={{
                                text: `${key+1}`,
                                color: '#fff',
                                fontSize: '12px'  
                            }}
                            key={key}
                        />
                    )
                })
            } 
   
          <InfoWindow onClose={onInfoWindowClose}>
              <div>
                <h1>{selectedPlace?.name}</h1>
              </div>
          </InfoWindow>

        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBvSs172REoJDfGBI9XEWehmXlydhdcKEY")
  })(MapContainer)

//export default Map;