import { React } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player'
import Layout from "../../components/Layout/Layout";
import Map from "../../components/Map/Map";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQs from "../../components/FAQs/FAQs";
import { triggerHellobox } from "../../utils/utils";


const Contact = () => {
    const { t } = useTranslation();

    var points = [
        {lat: 27.9670148, lng: -111.0156101},
		{lat: 27.970871,lng: -111.0189648},
		{lat: 27.967337, lng: -111.0164494},
		{lat: 27.9631975, lng: -111.0269085},
		{lat: 27.962701, lng: -111.0286713},
		{lat: 27.9596787, lng: -111.0349634},
		{lat: 27.9632381, lng: -111.0251464},
		{lat: 27.9582041, lng: -111.0870371},
    ]

    return (
        <Layout>
            <section className="contact-hero bg-secondary-med bg-stripe bg-stripe--darker py-2">
                <Container className='text-center text-md-start mt-md-5'>
                    <Row className='pt-5 text-center justify-content-center'>
                        <Col className="text-center">
                            <div className="hero-banner" style={{ backgroundImage: `url("images/img-contact.png")`, backgroundPosition: 'center 30%' }}>
                                <div className="hero-bg">
                                    <ReactPlayer 
                                        url={"video/manifesto.mov"} 
                                        playing={true} 
                                        controls={false}
                                        width={'100%'} 
                                        height={'100%'}
                                        muted={true}
                                        loop={true} />
                                </div>
                                <div style={{ zIndex: 1 }}>
                                    <h1 className="text-uppercase font-styled-bold text-white size-title mb-4" dangerouslySetInnerHTML={{ __html: t("contact.hero.title") }}>
                                    </h1>
                                    <Button variant="secondary-high" className="hellobox-trigger text-white" onClick={triggerHellobox }>{t("buttons.meetEvamar")}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-med">
                <Container className='text-center text-md-start'>
                    <Row className='py-5 text-center justify-content-center'>
                        <Col md='6' className="text-start">
                            <Row className='py-5 text-start justify-content-center'>
                                <Col>
                                    <div className="directory-venue mb-5">
                                        <div className="directory--venue-name mb-2 font-styled-bold size-subtitle text-white">San Carlos</div>
                                        <p className="mb-0">
                                            Blvd. Rafael Jesús Caballero <br />Predio El Baviso, porción B. <br />San Carlos Nvo. Guaymas, Son.
                                        </p>
                                        <a href={`https://api.whatsapp.com/send?phone=526624617993&text=${t("whatsapp.message")}`} target="_blank"><span className="icon icon--whatsapp"></span></a>
                                    </div>
                                    <div className="directory-venue mb-5 mb-5">
                                        <div className="directory--venue-name mb-2 font-styled-bold size-subtitle text-white">Hermosillo</div>
                                        <p className="mb-0">
                                            Luis Donaldo Colosio #887 <br /> Plaza La Encantada, Local 47<br /> 83224 Hermosillo, Son.
                                        </p>
                                        <a href={`https://api.whatsapp.com/send?phone=526624617993&text=${t("whatsapp.message")}`} target="_blank"><span className="icon icon--whatsapp"></span></a>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="directory-venue mb-5 mb-5">
                                        <div className="directory--venue-name mb-2 font-styled-bold size-subtitle text-white">Cd. Obregón</div>
                                        <p className="mb-0">
                                            Puebla #167 <br /> Centro C.P.85000<br /> Cd. Obregón, Son.
                                        </p>
                                        <a href={`https://api.whatsapp.com/send?phone=526624617993&text=${t("whatsapp.message")}`} target="_blank"><span className="icon icon--whatsapp"></span></a>
                                    </div>
                                </Col>
                            </Row>
                            <div className="directory-spots mb-5">
                                <ol className="list-ol">
                                    <li><span>1</span> Playa San Francisco</li>
                                    <li><span>2</span> Mauuka Beach Club </li>
                                    <li><span>3</span> Bartina 64</li>
                                    <li><span>4</span> Los Arbolitos</li>
                                    <li><span>5</span> Meri Meri Coffee</li>
                                    <li><span>6</span> Coralito Beach Club</li>
                                </ol>
                            </div>
                        </Col>
                        <Col md='6' style={{ position: "relative", minHeight: "75vh" }}>
                            <Map 
                                center={{
                                    lat: 27.971278,
                                    lng: -111.012778
                                }} 
                                points={points} 
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactForm />
            { /*
                <FAQs />
                */}
        </Layout>
    )
}

export default Contact;