import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons';

const CustomerReviews = () => {
    const { t } = useTranslation();

    return (
        <Container className='text-center text-md-start'>
            <Row className='justify-content-md-center'>
                <Col md='4' className="px-5 py-3">
                    <div>
                        <Icon.StarFill className="mx-1 text-primary ms-0" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                    </div>
                    <b>{t("fractional.testimonials.t1.title")}</b>
                    <p>{t("fractional.testimonials.t1.comment")}</p>
                    <div className="text-muted"><b>Luisa</b>, {t("fractional.testimonials.t1.date")}</div>
                </Col>
                <Col md='4' className="px-5 py-3">
                    <div>
                        <Icon.StarFill className="mx-1 text-primary ms-0" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                    </div>
                    <b>{t("fractional.testimonials.t2.title")}</b>
                    <p>{t("fractional.testimonials.t2.comment")}</p>
                    <div className="text-muted"><b>Rebeca</b>, {t("fractional.testimonials.t2.date")}</div>
                </Col>
                <Col md='4' className="px-5 py-3">
                    <div>
                        <Icon.StarFill className="mx-1 text-primary ms-0" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-primary" />
                        <Icon.StarFill className="mx-1 text-muted" />
                    </div>
                    <b>{t("fractional.testimonials.t3.title")}</b>
                    <p>{t("fractional.testimonials.t3.comment")}</p>
                    <div className="text-muted"><b>Rogelio</b>, {t("fractional.testimonials.t3.date")}</div>
                </Col>
            </Row>
        </Container>
    )
}

export default CustomerReviews;