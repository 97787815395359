import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

const Slider = props => {

    const [slides,setSlides] = useState([]);
    const [controls, setControls] = useState(true);

    useEffect(() => {
        setSlides(props.slides);
        setControls(props.controls);
    },[slides]);

  return (
    <>
        <Carousel fade controls={controls} indicators={controls}>
            {
                slides?.map((s,key) =>
                    <Carousel.Item key={key} style={{ backgroundImage: `url(${s?.image})` }}>
                        {
                            s?.title &&
                            <Carousel.Caption>
                                <Container className='text-center text-md-start'>
                                    <Row className='py-5 justify-content-md-center'>
                                        <Col xs lg="12">
                                            <h3 className="font-styled" dangerouslySetInnerHTML={{ __html: s?.title}}></h3>
                                            { s.link &&
                                                <p><a className="text-white" href={s?.link?.href}>{s?.link?.label}</a></p>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Caption>
                        }
                    </Carousel.Item>
                )
            }
        </Carousel>
    </>
  );
}

export default Slider;