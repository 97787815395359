import { React } from "react";
import { Container, Row, Col, Table } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import ContactForm from "../../components/ContactForm/ContactForm";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import FAQs from "../../components/FAQs/FAQs";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";

const Fraccional = () => {
    const { t } = useTranslation();

    const prosTable = [
        {   label: t("fractional.table.row1.title"),
            evamar: true, traditional: true, hotel: false },
        {   label: t("fractional.table.row2.title"),
            evamar: true, traditional: true, hotel: false },
        {   label: t("fractional.table.row3.title"),
            evamar: true, traditional: false, hotel: true },
        {   label: t("fractional.table.row4.title"),
            evamar: true, traditional: false, hotel: true },
        {   label: t("fractional.table.row5.title"),
            evamar: true, traditional: false, hotel: true },
        {   label: t("fractional.table.row6.title"),
            evamar: true, traditional: false, hotel: false },
        {   label: t("fractional.table.row7.title"),
            evamar: true, traditional: false, hotel: false },
        {   label: t("fractional.table.row8.title"),
            evamar: true, traditional: false, hotel: false },
    ];

    return (
        <Layout>
            <section className="bg-stripe bg-secondary-med pt-5">
                <Container className='text-center text-md-start'>
                    <Row className='pt-4 justify-content-md-center'>
                        <Col>
                            <VideoPlayer url='https://www.youtube.com/watch?v=shm7Z-CuB6g&rel=0' cover='video/evamar-video2-cover.png' caption={t("video.fractional.title")} desc={t("video.fractional.desc")} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-light py-5">
                <CustomerReviews />
            </section>
            <section className="bg-secondary-med py-2">
                <Container id="fraccional-pros" className='text-center text-md-start mt-5'>
                    <Row className='justify-content-md-center'>
                        <h2 className="text-center mt-lg-3">{t("fractional.features.title")}</h2>
                    </Row>
                    <Row className='pt-2 pb-0 text-center justify-content-center'>
                        <Col md='3' className="fractional-pros--item px-5 px-md-4 py-1">
                            <span className="icon icon--propiedad"></span> <br />
                            <h5>{t("fractional.features.f1.title")}</h5>
                            <p>{t("fractional.features.f1.desc")}</p>
                        </Col>
                        <Col md='3' className="fractional-pros--item px-5 px-md-4 py-1">
                            <span className="icon icon--plusvalia"></span> <br />
                            <h5>{t("fractional.features.f2.title")}</h5>
                            <p>{t("fractional.features.f2.desc")}</p>
                        </Col>
                        <Col md='3' className="fractional-pros--item px-5 px-md-4 py-1">
                            <span className="icon icon--flexibilidad"></span> <br />
                            <h5>{t("fractional.features.f3.title")}</h5>
                            <p>{t("fractional.features.f3.desc")}</p>
                        </Col>
                    </Row>
                    <Row className='pt-1 pb-5 mb-4 text-center justify-content-center'>
                        <Col md='3' className="fractional-pros--item px-5 px-md-4 py-1">
                            <span className="icon icon--comodidad"></span> <br />
                            <h5>{t("fractional.features.f4.title")}</h5>
                            <p>{t("fractional.features.f4.desc")}</p>
                        </Col>
                        <Col md='3' className="fractional-pros--item px-5 px-md-4 py-1">
                            <span className="icon icon--app--2"></span> <br />
                            <h5>{t("fractional.features.f5.title")}</h5>
                            <p>{t("fractional.features.f5.desc")}</p>
                        </Col>
                    </Row>
                </Container>
                <Container className='text-center text-md-start mb-5'>
                    <Row className='justify-content-md-center'>
                        <Table responsive id="fractional-tbl" className="bg-white">
                            <thead>
                                <tr>
                                    <th colSpan={4} className='text-center bg-primary text-white fs-2 py-3'>{t("fractional.table.title")}</th>
                                </tr>
                                <tr>
                                    <th className="pt-3 "></th>
                                    <th className="pt-3 text-center text-muted" width={'20%'}>EVAMAR</th>
                                    <th className="pt-3 text-center text-muted" width={'20%'}>{t("fractional.table.col2.title")}</th>
                                    <th className="pt-3 text-center text-muted" width={'20%'}>{t("fractional.table.col3.title")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    prosTable.map((p,key) => 
                                        <tr key={key}>
                                            <td className="text-left px-4">{p.label}</td>
                                            <td className="text-center">{p.evamar && <span className="icon icon--checked"></span>} {!p.evamar && <span className="icon icon--none"></span>}</td>
                                            <td className="text-center">{p.traditional && <span className="icon icon--checked"></span>} {!p.traditional && <span className="icon icon--none"></span>}</td>
                                            <td className="text-center">{p.hotel && <span className="icon icon--checked"></span>} {!p.hotel && <span className="icon icon--none"></span>}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Container>
            </section>
            <section className="app-wp bg-conexion-light py-2">
                <Container className='text-center text-md-start mt-5'>
                    <Row className='justify-content-md-center'>
                        <h2 className="text-center mt-lg-3">{t("fractional.app.title")}</h2>
                        <p className="text-center" dangerouslySetInnerHTML={{ __html: t("fractional.app.subtitle") }}></p>
                    </Row>
                    <Row className='py-5 text-center'>
                        <Col className="px-3 px-lg-5 py-3">
                            <img className="mb-4" src="./images/img-conexion-intro-1.jpg" alt="" />
                            <h5>{t("fractional.app.feature1.title")}</h5>
                            <p>{t("fractional.app.feature1.desc")}</p>
                        </Col>
                        <Col className="px-3 px-lg-5 py-3">
                            <img className="mb-4" src="./images/img-conexion-intro-2.jpg" alt="" />
                            <h5>{t("fractional.app.feature2.title")}</h5>
                            <p>{t("fractional.app.feature2.desc")}</p>
                        </Col>
                        <Col className="px-3 px-lg-5 py-3">
                            <img className="mb-4" src="./images/img-conexion-intro-3.jpg" alt="" />
                            <h5>{t("fractional.app.feature3.title")}</h5>
                            <p>{t("fractional.app.feature3.desc")}</p>
                        </Col>
                        <Col className="px-3 px-lg-5 py-3">
                            <img className="mb-4" src="./images/img-conexion-intro-4.jpg" alt="" />
                            <h5>{t("fractional.app.feature4.title")}</h5>
                            <p>{t("fractional.app.feature3.desc")}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactForm />
            { /*
                <FAQs />
                */}
        </Layout>
    )
}

export default Fraccional;