const propertyList = [
    {
        id: 1,
        link: '/renta/renta-sample',
        slug: 'renta-sample',
        name: 'ARENA 101',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=1',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 2,
        link: '/renta/renta-sample-2',
        slug: 'renta-sample-2',
        name: 'ARENA 702',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=2',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 3,
        link: '/renta/renta-sample-3',
        slug: 'renta-sample-3',
        name: 'Caracol 203',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=3',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 4,
        link: '/renta/renta-sample-4',
        slug: 'renta-sample-4',
        name: 'Arena 702',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=4',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 5,
        link: '/renta/renta-sample-5',
        slug: 'renta-sample-5',
        name: 'Arena 203',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=5',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
    {
        id: 6,
        link: '/renta/renta-sample-6',
        slug: 'renta-sample-6',
        name: 'Caracol 203',
        account_name: 'EVAMAR',
        location: 'SAN CARLOS',
        description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus inventore delectus reiciendis vitae enim, culpa consequatur quaerat ad nulla in blanditiis, officiis est, maiores hic nam unde ex excepturi ut.',
        rooms: 1,
        bathrooms: 1,
        meters: 10,
        squarefeets: 20,
        image: 'https://picsum.photos/1200/800?random=6',
        gallery: [
            {
                id: 1,
                image: "https://picsum.photos/1200/800?random=10",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 2,
                image: "https://picsum.photos/1200/800?random=20",
                alt: 'First Slide',
                title: '',
            },
            {
                id: 3,
                image: "https://picsum.photos/1200/800?random=30",
                alt: 'Second Slide',
                title: '',
            }
        ],
    },
];

export default propertyList;