import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const PropertyCard = props => {
    const { t, i18n } = useTranslation();

    const [property, setProperty] = useState(null);
    const [cardType, setCardType] = useState(null);

    useEffect(() => {
        setProperty(props.property);
        setCardType(props.variant);
    },[])

    const Grid = () => (
        <div id={property?.slug} className="apartment apartment--grid">
            <Link to={(property?.link ? property.link : `/compra/${property?.slug}`)} className="apartment--link">
                <Row className='text-center justify-content-center'>
                    <Col>
                        <span className="apartment--image" style={{backgroundImage: `url('${property?.image}')`}}></span>
                        <div className="apartment--title text-uppercase"><span className="apartment--name">{property?.name}</span> - {property?.account_name} <span className="apartment--badge">{property?.location}</span></div>
                        <div className="apartment--details text-muted">{property?.rooms} hab | {property?.bathrooms} baño | {i18n.language == "es" ? `${property?.meters}m2` : `${property?.squarefeets}sqft`} </div>
                    </Col>
                </Row>
            </Link>
        </div>
    )

    const Card = () => (
        <>
            <hr className="text-white" />
            <div id={property?.slug} className="apartment pb-5">
                <Row className='pt-5 text-center justify-content-center'>
                    <Col md="3" lg='2' className="text-start">
                        <div className="apartment--name font-styled-bold text-primary text-uppercase">{property?.name}</div>
                        { i18n.language == "es" && <div className="apartment--size text-muted">Desde {property?.meters}m2</div>
                        }
                        { i18n.language == "en" && <div className="apartment--size text-muted">From {property?.squarefeets}sqf</div>
                        }
                        <Button variant="info bg-white mt-3 mb-2 me-2" onClick={() => document.querySelector(".calendly-badge-widget").click()}>{t("buttons.appointment")}</Button>
                        <Button variant="secondary text-white mt-3 mb-2" href="https://evamar.mx/360/" target="_blank">{t("buttons.tour")}</Button>
                    </Col>
                    <Col md="4" lg='2'>
                        <ul className="list-ol text-start mb-4">
                            { property?.description[i18n.language]?.map((o,key) => 
                                <li key={key}><span></span> { o }</li>
                            ) }
                        </ul>
                    </Col>
                    <Col>
                        <img src={ property?.image}  alt=""/>
                    </Col>
                </Row>
            </div>
        </>
    )

    const CircleCard = () => (
        <div className="property-card">
            <div className="property-card--image mb-4" style={{"backgroundImage": `url('${property?.thumb}')`}}>
                <Button href={ `/departamentos#${property?.slug}` } variant="primary">{t("buttons.meet")}</Button>
            </div>
            <h3 className="property-card--name">{property?.name}</h3>
            <small className="property-card--rooms">{property?.rooms} {property?.rooms > 1 ? t("apartments.rooms") : t("apartments.room")}</small>
        </div>
    )

    switch(props.variant){
        case "circle": return <CircleCard />;
        case "card": return <Card />;
        case "grid": return <Grid />;
    }
}

export default PropertyCard;