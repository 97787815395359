import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './PropertyRental.scss';
import Layout from "../../components/Layout/Layout";
import Map from "../../components/Map/Map";

import samplePropertyList from '../../data/propertyList4rent';
import Slider from "../../components/Slider/Slider";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";


const PropertyRental = (props) => {
    const { slug } = useParams();
    const { t, i18n } = useTranslation();

    const [property, setProperty] = useState(null);

    useEffect(() => {
        let p = samplePropertyList.find(p => p.slug === slug);
        setProperty(p);
    },[])

    return (
        <>
            <Layout>
                <section className="bg-secondary-light pt-5">
                    <Container>
                        <Row>
                            <Col lg='8' className="property--main-slider">
                                { property?.gallery &&
                                    <Slider slides={property?.gallery} />
                                }
                            </Col>
                            <Col>
                                <span className="property--second-image property--gallery-sub-image" style={{ backgroundImage: `url(${property?.image})` }}></span>
                                <span className="property--third-image  property--gallery-sub-image" style={{ backgroundImage: `url(${property?.image})` }}>
                                    <Button variant='info' className="btn-gallery bg-white" size="sm">Más fotos</Button>
                                </span>
                            </Col>
                        </Row>
                        <Row className="pt-4 pb-5">
                            <Col lg='8'>
                                <Row>
                                    <Col>
                                        <Row className="mb-2">
                                            <h1>{property?.name}</h1>
                                        </Row>
                                        <Row className="mb-2">
                                            <div>
                                                {property?.rooms} hab | {property?.bathrooms} baño | {i18n.language == "es" ? `${property?.meters}m2` : `${property?.squarefeets}sqft`}
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <span className="subtitle">UBICACIÓN</span>
                                                <div>
                                                    {property?.location}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mb-5">
                                            <div>
                                                {property?.description}
                                            </div>
                                        </Row>
                                        <Row className="mb-5">
                                            <span className="subtitle">PARA TI</span>
                                            <div>
                                                <ul>
                                                    <li>Item 1</li>
                                                    <li>Item 2</li>
                                                    <li>Item 3</li>
                                                </ul>
                                            </div>
                                        </Row>
                                        <Row className="mb-5">
                                            <span className="subtitle">AMENIDADES</span>
                                            <div>
                                                <ul>
                                                    <li>Item 1</li>
                                                    <li>Item 2</li>
                                                    <li>Item 3</li>
                                                </ul>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pb-5">
                                    <Col style={{ position: "relative", minHeight: "400px" }}>
                                        <span className="subtitle mb-3 d-block">UBICACIÓN</span>
                                        <Map 
                                            center={{
                                                lat: 27.971278,
                                                lng: -111.012778
                                            }} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="mb-4">
                                    <Col>                                        
                                        <img src="https://placehold.co/500x700?text=[cloudbeds+shopping+cart+widget]" alt="" />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <span className="subtitle">REGLAMENTO:</span>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti, molestias odio. Est excepturi voluptatum quia nam iste maiores ipsa, animi iure, accusantium ipsum illum. Eligendi illo corrupti nulla neque excepturi!
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <span className="subtitle">POLÍTICAS:</span>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deleniti, molestias odio. Est excepturi voluptatum quia nam iste maiores ipsa, animi iure, accusantium ipsum illum. Eligendi illo corrupti nulla neque excepturi!
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="bg-secondary-light py-4">
                    <Container className='text-center text-md-start'>
                        <Row className='pt-5 text-center'>
                            <h3 className="">Nuestros huéspedes</h3>
                        </Row>
                    </Container>
                    <CustomerReviews />
                </section>
            </Layout>
        </>
    )
}

export default PropertyRental;